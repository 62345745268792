<template>
    <div id="app" style="width: 100%;">
        <keep-alive>
            <router-view></router-view>
        </keep-alive>
    </div>
</template>

<script>
export default {
    name: "App",
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "MicrosoftYaHei";
}
body {
    font-family: "MicrosoftYaHei";
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
}
div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    vertical-align: baseline;
}
.zxyj-content-box {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    height: 100%;
}
.xzyj-content{
    width: 1200px;
}
.xzyj-flex-row{
    display: flex;
    height: 100%;
}
</style>