import Layout from "@/views/layout/Layout";
/* eslint-disable */
export default [
	{
		component: Layout,
		path: "/caseDetail",
		children: [
		    {
		        name: "caseDetail",
		        path: "index.html",
		        component: () => import('@/views/case/Detail'),
		        meta: {
		            title: '案例详情',
		            identify: '',
		            permission: '',
		            distinguish:'caseDetail'
		        }
		    }
		    
		]
	}
]