import Layout from "@/views/layout/Layout";

export default [
    {
        path: "/case",
        component: Layout,
        children: [
            {
                name: "case",
                path: "index.html",
                component: () => import('@/views/case/Case'),
                meta: {
                    title: '项目案例',
                    identify: '',
                    permission: '',
                    distinguish:'case'
                }
            }
            
        ]
		
		

    }
]