import Layout from "@/views/layout/Layout";
/* eslint-disable */
export default [
  
	{
		component: Layout,
		path: "/factoryDetail",
		children: [
		    {
		        name: "factoryDetail",
		        path: "index.html",
		        component: () => import('@/views/case/Detail'),
		        meta: {
		            title: '工厂详情',
		            identify: '',
		            permission: '',
		            distinguish:'factoryDetail'
		        }
		    }
		    
		]
	}
]