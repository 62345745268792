import VueRouter from 'vue-router'
import Home from './Home'
import Case from './Case'
import Factory from './Factory'
import News from './News'
import About from './About'
import Products from './Products'
import CaseDetail from './CaseDetail.js'
import FactoryDetail from './FactoryDetail.js'
import NewsDetail from './NewsDetail.js'
import Appdown from './Appdown.js'
let routes = [
  ...Home,
  ...Case,
  ...Factory,
  ...News,
  ...About,
  ...Products,
  ...CaseDetail,
  ...FactoryDetail,
  ...NewsDetail,
  ...Appdown
 
];

let index = new VueRouter({
  mode: 'history',
  routes
});
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default index;
