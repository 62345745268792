import Layout from "@/views/layout/Layout";

export default [
    {
        path: "/appdown",
        component: Layout,
        children: [
            {
                name: "appdown",
                path: "index.html",
                component: () => import('@/views/appdown/Appdown'),
                meta: {
                    title: 'app下载',
                    identify: '',
                    permission: '',
                    distinguish:'appdown'
                }
            }
        ]

    }
]