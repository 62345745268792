import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import router from './router/Index';
import { Button, Form,FormItem,Carousel,CarouselItem,Input,Pagination,Breadcrumb,BreadcrumbItem,DatePicker,Select,Option,Cascader } from 'element-ui';
//360安全浏览器兼容
import Meta from 'vue-meta'
// 样式
import '@/assets/styles/ui.scss';

Vue.use(VueRouter);
Vue.use(Meta);
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Input);
Vue.use(Pagination);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(DatePicker);
Vue.use(Select)
Vue.use(Option)
Vue.use(Cascader)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  mounted() {
     document.dispatchEvent(new Event('render-event'))
   },
}).$mount('#app')
