import Layout from "@/views/layout/Layout";

export default [
    {
        path: "/",
        component: Layout,
        children: [
            {
                name: "home",
                path: "",
                component: () => import('@/views/homePage/HomePage'),
                meta: {
                    title: '首页',
                    identify: '',
                    permission: '',
                    distinguish:'home'
                }
            }
        ]

    }
]