import Layout from "@/views/layout/Layout";

export default [
    {
        path: "/news",
        component: Layout,
        children: [
            {
                name: "news",
                path: "index.html",
                component: () => import('@/views/news/News'),
                meta: [
                    {title: '公司新闻动态-鑫昇腾'},
                    {identify: ''},
                    {permission: ''},
                    {distinguish:'news'}
                ]
            }
        ]

    }
]