<template>
	
	<div id="layout" class="layout">
		<div class="top-bar">
			<div class="header clearfix">
				<div class="zxyj-content-box">
					<div class="xzyj-content">
						<div class="xzyj-flex-row">
							<div style="display: flex;">
								<router-link to="/">
									<div class="logo" @click="route = '/'">
										<img src="@/assets/images/logo.png" alt="logo" />
									</div>
								</router-link>
							</div>
							<div class="navigation-bar">
								<router-link to="/">
									<div :class="route === '/' || route == 'home' ? 'bar-item-current' : 'bar-item'" @click="route = '/'">
										首页
									</div>
								</router-link>
								<a :class="route == 'products' ? 'bar-item-current' : 'bar-item'" href="/products/index.html">
									<div>产品介绍</div>
								</a>
								<a :class="route == 'case'|| route == 'caseDetail'? 'bar-item-current' : 'bar-item'" href="/case/index.html">
									<div>项目案例</div>
								</a>
								<a :class="route == 'factory'||route == 'factoryDetail' ? 'bar-item-current' : 'bar-item'" href="/factory/index.html">
									<div>加工工厂</div>
								</a>

								<a :class="route == 'news'||route == 'newsDetail' ? 'bar-item-current' : 'bar-item'" href="/news/index.html">
									<div>聚焦鑫昇腾</div>
								</a>
								<a :class="route == 'about' ? 'bar-item-current' : 'bar-item'" href="/about/index.html">
									<div>关于鑫昇腾</div>
								</a>
								<a :class="route == 'appdown' ? 'bar-item-current' : 'bar-item'" href="/appdown/index.html">
									<div >APP下载</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
		<div class="back"></div>
		<transition name="fadee" mode="out-in">
			<keep-alive>
				<router-view></router-view>
			</keep-alive>
		</transition>
		<div style="display: flex;flex-direction: column;align-items:center ;" class="footer">
			<div style="display: flex;flex-direction: row;width: 1200px;justify-content: space-between;">
			<div style="display: flex;flex-direction:   column; justify-content:space-evenly; height: 160px;">
				<div class="suba">
					<div class="img">
						<img src="../../assets/images/logofooter@3x.png" alt="logo" />
					</div>
				</div>
				<div class="subb">
					<router-link to="/">
						<div :class="route === '/' ? 'bar-item-current' : 'bar-item'" @click="route = '/'">
							首页
						</div>
					</router-link>
					<div class="span">/</div>
					<a class="bar-item" href="/products/index.html">
						<div class="bar-item">产品介绍</div>
					</a>
					<div class="span">/</div>
					<a href="/case/index.html">
						<div class="bar-item">项目案例</div>
					</a>
					<div class="span">/</div>
					<a class="bar-item" href="/factory/index.html">
						<div class="bar-item">加工工场</div>
					</a>
					<div class="span">/</div>
					<a class="bar-item" href="/news/index.html">
						<div class="bar-item">聚焦鑫昇腾</div>
					</a>
					<div class="span">/</div>
					<a class="bar-item" href="/about/index.html">
						<div class="bar-item">关于鑫昇腾</div>
					</a>
					<div class="span">/</div>
					<a href="#">
						<div class="bar-item">APP下载</div>
					</a>
				</div>
			</div>
			<div style="display: flex;align-items: center;justify-content: space-between;width: 530px;">
				<div style=" display: flex;flex-direction: column;justify-content:space-around;align-items: center;">
					<img style="width: 80px;height: 80px;" src="../../assets/images/ios07.png" />
					<div style="margin-top: 9px;">扫码下载iOS版</div>
				</div>
				<div style="display: flex;flex-direction: column;justify-content:space-around;align-items: center;">
					<img style="width: 80px;height: 80px;" src="../../assets/images/and09.png" />
					<div style="margin-top: 9px;">扫码下载安卓版</div>
				</div>
				<div style="display: flex;flex-direction: column;justify-content:space-around">
					<div><img src="../../assets/images/homepage-ico-lianxiwomen.png" style="width:16px;"/> <span>联系我们</span></div>
					<div style="align-items: baseline;display: flex;">
						<div style="color: #BB8B3D; font-size: 36px; line-height: 42px; font-weight: 900;font-family: DINCond-Black, DINCond;">15505514531</div>
						<div style="font-size: 12px;">(微信同号)</div>
					</div>
					<div style="align-items: baseline;display: flex;">
						<div style="color: #BB8B3D; font-size: 36px; line-height: 42px; font-weight: 900;font-family: DINCond-Black, DINCond;">17721547063</div>
						<div style="font-size: 12px;">(微信同号)</div>
					</div>
				</div>
			</div>
			
         </div>
		</div>
		
		<div style="display: flex;flex-direction: column;height: 50px;align-items: center;justify-content:space-around;background-color: #222222;color: #999999;">
		        <div style="display: flex; flex-direction: column;  width: 1200px;align-items: center;">
		            <div style="display: flex;flex-direction: row;align-items: center;">
		                <div class="footer-copyright" >Copyright © 2020 江苏鑫昇腾科技发展股份有限公司 版权所有 
							<a class="footer-link" style="color: rgb(153, 153, 153); text-decoration: none; background-color: transparent;" href="https://beian.miit.gov.cn/#/Integrated/index">苏ICP备2021002523号</a></div>
		                <!-- <div style="display: flex; flex-direction: row; margin-left: 10px;">
							<div style="display: flex;">
		                    <img style="object-fit: none;" src="../../assets/images/police.png">
							</div>
		                    <div style="display: flex;" ><a href="https://beian.miit.gov.cn/portal/registerSystemInfo?recordcode=32011402010445">苏公网安备 32011402010445号</a></div>
		                </div> -->
		            </div>
		           
		
		        </div>
		    </div>
	</div>
</template>

<script>
	export default {
		name: "Layout",
		data() {
			return {
				route: this.$route.meta.distinguish,
			};
		},
		mounted() {
			console.log("route:", this.route)
		},
		watch: {
			$route(to) {
				if (to.path === "/case_detail") {
					this.route = "/case";
				} else if (to.path === "/factory_detail") {
					this.route = "/factory";
				} else if (to.path === "/news_detail") {
					this.route = "/news";
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.top-bar {
		position: fixed;
		z-index: 101;
		background: white;
		left: 0;
		width: 100%;

		.header{
			position: relative;
			height: 68px;
		}
		.xzyj-flex-row {
			-webkit-align-items: center;
			align-items: center;
			-webkit-justify-content: space-between;
			justify-content: space-between;
		}
		.logo {

			margin: 0 40px 0 40px;

			img {
				width: 160px;
			}
		}

		.navigation-bar {
			margin: 0 40px 0 40px;
			display: flex;
			align-items: center;
			text-align: center;
			

			.bar-item {
				min-width: 100px;
				font-size: 16px;
				font-family: MicrosoftYaHei;
			    color: #333;
				padding: 0 15px;
			}

			.bar-item-current {
				background-color: #122c8d;
				min-width: 100px;
				font-size: 16px;
				color: white;
				margin: 0 15px;
				font-family: MicrosoftYaHei;
				padding: 0.3rem 0.5rem;
				border: 1px solid #fff;
			}
		}
	}

	.footer {
		height: 170px;
		// margin-top: 100px;
		padding: 0 30px 0 30px;
		background: #122c8d;
		color: #fff;
		font-weight: 300;
		bottom: 1vh;
		z-index: 0;
		font-size: 0.8rem;
		cursor: default;

		.suba {
			width: 300px;
			padding: 20px 0;
		}

		.subb {
			display: flex;
			width: 400px;
			justify-content: space-between;

			div {
				font-family: MicrosoftYaHei;
			}
		}

		.subc {
			padding: 1.5rem 0 2rem;
		}

		.br {
			display: inline-block;
			width: 2vw;
		}
	}
	.footer-link:hover {
		color: #c7000b;
	}
	.footer-copyright a, .footer-copyright span {
		display: inline-block;
		margin-right: 10px;
		font-size: 14px;
		line-height: 18px;
	}
	a {
		text-decoration: none;
		background-color: transparent;
	}
</style>
