import Layout from "@/views/layout/Layout";

export default [
    {
        path: "/factory",
        component: Layout,
        children: [
            {
                name: "factory",
                path: "index.html",
                component: () => import('@/views/factory/Factory'),
                meta: {
                    title: '加工工厂',
                    identify: '',
                    permission: '',
                    distinguish:'factory'
                }
            }
        ]

    }
]