import Layout from "@/views/layout/Layout";

export default [
    {
        path: "/about",
        component: Layout,
        children: [
            {
                name: "about",
                path: "index.html",
                component: () => import('@/views/about/About'),
                meta: {
                    title: '关于鑫昇腾',
                    identify: '',
                    permission: '',
                    distinguish:'about'
                }
            }
        ]

    }
]